import React from 'react';

const SectionWithImageAndText = ({ id, title, image, description, bulletPoints, reverse = false }) => {
  return (
    <section id={id} className={`section-with-image-text ${reverse ? 'reverse' : ''}`}>
      <div className="image">
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
             <div className="text">
               <ul>
                 {bulletPoints.map((text, i) => (
                   <li key={i + text}>{text}</li>
                 ))}
               </ul>
             </div>
           </div>
    </section>
  );
};

export default SectionWithImageAndText;
