import React, { useRef, useState, useEffect } from 'react';
import SlideItem from './SlideItem';
import TinySlider from 'tiny-slider-react';
import { useTranslation } from 'react-i18next';

import bgImage from '../../assets/images/background/bg.jpg';
import awardImage from '../../assets/images/awards-logo.png';
import WinnerHomepage from '../Captive/WinnerHomepage';

const settings = {
  container: '.my-slider-11',
  loop: true,
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplay: true,
  autoHeight: true,
  controls: true,
  controlsContainer: '.tns-controls',
  gutter: 0,
  autoplayButton: false,
  autoplayButtonOutput: false,
};

const Banner = () => {
  const listRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowContent(true);
    }, 500);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Responsive styles based on screen width
  const responsiveStyles = {
    logoSize: windowWidth > 1200 ? '170px' : windowWidth > 768 ? '140px' : '110px',
    titleFontSize: windowWidth > 1200 ? '30px' : windowWidth > 768 ? '25px' : '20px',
    descriptionFontSize: windowWidth > 1200 ? '25px' : windowWidth > 768 ? '20px' : '16px',
    linkFontSize: windowWidth > 1200 ? '16px' : windowWidth > 768 ? '14px' : '12px',
    containerRight:  windowWidth > 1200 ? '10%':windowWidth > 768 ? '5%' : '5%', 
    containerTop: windowWidth > 1200 ? '45%' : windowWidth > 768 ? '40%' : '110px',
    isMobile: windowWidth<950 ? true : false
  };

  const socials = [
    {
      id: 1,
      href: 'https://www.linkedin.com/company/tendanz/',
      title: t('bannerSection.socials.linkedin'),
    },
  ];

  const banners = [
    {
      id: 1,
      bg: bgImage,
      bannerSubtitle: t('bannerSection.banners.0.bannerSubtitle'),
      bannerTitle: t('bannerSection.banners.0.bannerTitle'),
    },
  ];

  return (
    <section className="banner-section banner-one" style={{ position: 'relative', overflow: 'hidden' }}>
      <div className="left-based-text">
        <div className="base-inner">
          <div className="social-links">
            <ul className="clearfix">
              {socials.map(({ id, href, title }) => (
                <li key={id}>
                  <a href={href} target="_blank" rel="noopener noreferrer">
                    <span>{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="banner-carousel">
        <TinySlider settings={settings} ref={listRef}>
          {banners.map((slide) => (
            <SlideItem key={slide.id} slide={slide} ref={listRef} />
          ))}
        </TinySlider>
        <div className="tns-controls">
          <button className="tns-prev">
            <span className="icon fa fa-angle-left"></span>
          </button>
          <button className="tns-next">
            <span className="icon fa fa-angle-right"></span>
          </button>
        </div>
      </div>

          {!responsiveStyles.isMobile?
        <div
          style={{
            position: 'absolute',
            right: responsiveStyles.containerRight,
            top: responsiveStyles.containerTop,
            transform: `translateY(-50%) ${showContent ? 'scale(1)' : 'scale(0.8)'}`,
            opacity: showContent ? 1 : 0,
            textAlign: 'center',
            color: '#fff',
            backgroundColor: 'transparent',
            padding: '20px',
            borderRadius: '10px',
            zIndex: 10,
            maxWidth: '250px',
            transition: 'opacity 1s ease, transform 1s ease',
          }}
        >
          <a href="https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners" target="_blank"
  rel="noopener noreferrer">
            <img
              src={awardImage}
              alt={t('bannerSection.awards.logoAlt')}
              style={{
                width: responsiveStyles.logoSize,
                height: responsiveStyles.logoSize,
                marginBottom: '10px',
                cursor: 'pointer',
              }}
            />
          </a>

          <h3
            style={{
              fontSize: responsiveStyles.titleFontSize,
              margin: '5px 0',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {t('bannerSection.awards.title')}
          </h3>

          <p
            style={{
              fontSize: responsiveStyles.descriptionFontSize,
              lineHeight: '1.2',
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            {t('bannerSection.awards.description')}
          </p>

            <button style={{background:"transparent" , borderColor:"white", border:"solid white", marginTop:"15px", paddingBottom:"8px", paddingLeft:'10px', paddingRight:"10px"}} href="/captive">
          <a
            href="/captive"
            style={{
              display: 'block',
              marginTop: '7px',
              color: '#fff',
              textDecoration: 'underline',
              fontSize: responsiveStyles.linkFontSize,
            }}
          >
            {t('bannerSection.awards.learnMore')}
          </a>
          </button>
        </div> : <WinnerHomepage/>
        }      
    </section>


  );
};

export default Banner;
