import React from "react";
import { useTranslation } from 'react-i18next';
import awardImage from '../../assets/images/awards-logo.png';

const Winner=({homepage})=>{
      const { t, i18n } = useTranslation();
    return(
    <section id="winner" className="winner-section">
        <div className="profile">
    <a href="https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners" target="_blank"
  rel="noopener noreferrer">
            <img
              src={awardImage}
              alt={t('bannerSection.awards.logoAlt')}
              className="profile-img"
              style={{cursor: "pointer"}}
            />
          </a>

         
          </div>
          <div className="welcome-text">
          <h3
            style={{
              fontSize: "20px",
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
              {t('bannerSection.awards.description')}
           
          </h3>

          <p
            style={{
              fontSize: "20px",
              lineHeight: '1.2',
              textAlign: 'left',
            }}
          >
             {t('bannerSection.awards.title')}
          </p>
            <div >
            <button style={{background:"#162d3e" , margin: '0 auto', borderColor:"#162d3e", border:"solid #162d3e", paddingBottom:"8px", paddingLeft:'10px', paddingRight:"10px", borderRadius:"5px"}} href="https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners" target="_blank"
  rel="noopener noreferrer">
          <a
            href={ homepage ? "/captive" : "https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners"}
            target="_blank"
  rel="noopener noreferrer"
            style={{
              display: 'block',
              marginTop: '7px',
              color: 'white',
              fontSize: "17px",
              fontWeight: 'bold',             
            }}
          >
            {t('bannerSection.awards.learnMore')}
          </a>
          </button>
          </div>
          </div>
          </section>)
}

export default Winner