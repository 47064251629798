import React, { useState ,useRef , useEffect } from 'react';
import logo1 from '../../assets/images/logo-horis.png';
import { Link} from 'react-router-dom';
import LanguageSelector from "../LanguageSelector/LanguageSelector"
const Sidebar = ({ navItems, onNavClick }) => {

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarVisible(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button className="hamburger-menu" onClick={toggleSidebar} ref={sidebarRef}>
        &#9776; 
      </button>
      <aside className={`sidebar ${isSidebarVisible ? 'active' : 'hidden'}`}>
      <div className="sidebar-logo">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          <img src={logo1} alt="Tendanz Logo" />
        </Link>
      </div>
      <ul className="sidebar-nav">
        {navItems.map((item) => (
          <li key={item.id}>
            <a
              href={`#${item.id}`}
              onClick={(e) => {
                e.preventDefault();
                onNavClick(item.id);
              }}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
          <div className='sidebar-footer'>
      <LanguageSelector />
      </div>
    </aside>
    </>
  );
};

export default Sidebar;
