import React from 'react';
import Sidebar from '../components/Captive/Sidebar';
import WelcomeSection from '../components/Captive/WelcomeSection';
import SectionWithImageAndText from '../components/Captive/SectionWithImageAndText';
import TestimonialsSection from '../components/Captive/TestimonialsSection';
import '../styles/captivepage.css'
import ai from "../assets/images/captive/ai.jpg"
import data from "../assets/images/captive/data.jpg"
import management from "../assets/images/captive/management.jpg"
import risk from "../assets/images/captive/risk.jpg"
import frank from "../assets/images/captive/frank.jpg"
import santiago from "../assets/images/captive/santiago.jpg"
import Winner from '../components/Captive/Winner';
import { useTranslation } from 'react-i18next';

const CaptivePage = () => {
  const { t } = useTranslation();
  
  const navItems = [
    { id: 'welcome', name: t('welcome') },
    { id: 'winner', name: t('winner') },
    { id: 'actuarial', name: t('actuarial') },
    { id: 'transformation', name: t('transformation') },
    { id: 'data', name: t('data') },
    { id: 'ai', name: t('ai') },
    { id: 'testimonials', name: t('testimonials') },
  ];

  const onNavClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const testimonials = [
    {
      image: santiago,
      name: 'Santiago Gomez',
      job: t("job1"),  
      description: t("testimonial1")  
    },
    {
      image: frank,
      name: 'Franck Baron',
      job: t("job2"),  
      description: t("testimonial2")  
    },
  ];

  return (
    <div className="captive-page">
      <Sidebar navItems={navItems} onNavClick={onNavClick} />
      <div className="main-content">
        <div className="page-title">
          <h1>{t('captivesolutions')}</h1>
        </div>
        <WelcomeSection />
        <Winner />
        <SectionWithImageAndText
          id="actuarial"
          title={t('actuarial')}
          image={risk}
          description={t('actuarialDescription')}
          bulletPoints={[
            t('solvency'),
            t('actuarialFunction'),
            t('lossReserve'),
            t('pricing2'),
            t('lossModelling'),
            t('independentReviews')
          ]}
          reverse={true}
        />
        <SectionWithImageAndText
          id="transformation"
          title={t('transformation')}
          image={management}
          description={t('transformationDescription')}
          bulletPoints={[
            t('simplification'),
            t('outsourcing'),
            t('transformationDepartments')
          ]}
        />
        <SectionWithImageAndText
          id="data"
          title={t('data')}
          image={data}
          description={t('dataDescription')}
          bulletPoints={[
            t('dataGovernance2'),
            t('dataArchitecture2'),
            t('dataFlowManagement'),
            t('customDataVisualization')
          ]}
          reverse={true}
        />
        <SectionWithImageAndText
          id="ai"
          title={t('ai')}
          image={ai}
          description={t('aiDescription')}
          bulletPoints={[
            t('naturalLanguageProcessing'),
            t('chatbotSetup'),
            t('reportingAutomation')
          ]}
        />
        <TestimonialsSection testimonials={testimonials} />
      </div>
    </div>
  );
};

export default CaptivePage;
