import React,{ useRef, useState,useEffect} from "react";
import { useTranslation } from 'react-i18next';
import awardImage from '../../assets/images/awards-logo.png';

const WinnerHomepage = ({ homepage }) => {
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        const [showContent, setShowContent] = useState(false);
      
    useEffect(() => {
        const timeout = setTimeout(() => {
          setShowContent(true);
        }, 500);
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          clearTimeout(timeout);
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  const { t, i18n } = useTranslation();
  
  
  return (
    <section
      style={{
        backgroundColor: "#f5f5f5",  
        padding: '40px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column', 
        textAlign: 'center', 
      }}
    >
      <a
        href="https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          marginBottom: '20px',  
        }}
      >
        <img
          src={awardImage}
          alt={t('bannerSection.awards.logoAlt')}
          style={{
            cursor: "pointer",
            maxWidth: "150px",  
          }}
        />
      </a>

      <div
        style={{
          maxWidth: '600px', 
        }}
      >
        <h3
          style={{
            fontSize: "25px",
            fontWeight: 'bold',
            marginBottom: '5px',
          }}
        >
          {t('bannerSection.awards.description')}
        </h3>

        <p
          style={{
            fontSize: "20px",
            lineHeight: '1.5',
            marginBottom: '10px',
          }}
        >
          {t('bannerSection.awards.title')}
        </p>

        <div>
          <button
            style={{
              background: "#162d3e",
              borderColor: "#162d3e",
              border: "solid #162d3e",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            <a
              href="https://awards.captivereview.com/EuropeanAwards2024/en/page/2024-winners"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                color: 'white',
                fontSize: "17px",
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              {t('bannerSection.awards.learnMore')}
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default WinnerHomepage;
