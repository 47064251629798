import React from 'react';
import image from "../../assets/images/team/robert.jpeg";
import { useTranslation } from 'react-i18next';
const WelcomeSection = () => {
  const {t} = useTranslation()
  return (
    <section id="welcome" className="welcome-section">
      <div className="profile">
      <img src={image} alt="Robert" className="profile-img" />
      <h2>Robert Galbraith</h2>
        <p>{t("captiveLead")}</p>
      </div>
      <div className="welcome-text">
        <p>
        {t("RobDescription")}
        </p>
      </div>
    </section>
  );
};

export default WelcomeSection;
