import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import NavItem from '../Header/NavItem';
import { useRootContext } from '../../context/context';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { getPhoneNumber } from '../../utils/getPhoneNumber'; // Ensure this path is correct

// Import the images directly
import icon from '../../assets/images/icons/close-1-1.png';

// Embedded data
const data = {
  socials: [
    {
      id: 1,
      href: "https://www.linkedin.com/company/tendanz/",
      icon: "fab fa-linkedin",
    },
  ],
};

const MobileMenu = ({ onePage = false }) => {
  const { t } = useTranslation();
  const { menuStatus, toggleMenu } = useRootContext();
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const number = await getPhoneNumber();
      setPhoneNumber(number);
    };
    fetchPhoneNumber();
  }, []);

  const navItems = [
    { id: 1, name: 'home', href: '/' },
  { id: 2, name: 'services', href: '/#services' },
  {id: 3, name: 'captive', href: '/captive'},
  { id: 4, name: 'team', href: '/#team' },
  { id: 5, name: 'about', href: '/about-us' },
  ];

  return (
    <div className={`side-menu__block${menuStatus ? ' active' : ''}`}>
      <div onClick={toggleMenu} className="side-menu__block-overlay custom-cursor__overlay">
        <div className="cursor"></div>
        <div className="cursor-follower"></div>
      </div>
      <div className="side-menu__block-inner">
        <div className="side-menu__top justify-content-end">
          <a onClick={toggleMenu} className="side-menu__toggler side-menu__close-btn">
           
          </a>
          <button onClick={toggleMenu} className="side-menu__close-btn">
            <span className="icon fa fa-times"></span>
          </button>
        </div>

        <nav className="mobile-nav__container">
          <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
            <ul className="navigation clearfix">
              {navItems.map((navItem) => (
                <NavItem navItem={navItem} key={navItem.id} mobile />
              ))}
            </ul>
          </div>
        </nav>
        <div className="side-menu__sep"></div>
        <div className="side-menu__content">
          <LanguageSelector />
          <p></p>
          <p>
            <a href={`mailto:${t('mobileMenu.email')}`}>{t('mobileMenu.email')}</a> <br />
            <a href={`tel:${phoneNumber.replace(/\s+/g, '')}`}>{phoneNumber}</a>
          </p>
          <div className="side-menu__social">
            {data.socials.map(({ id, icon, href }) => (
              <a key={id} href={href} target="_blank" rel="noopener noreferrer">
                <i className={icon}></i>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
